import { PdfmakeConfig } from "./pdfmake-config";
import { DefaultHeaderConfig } from "./default-header-configs";
import Utils from "../../utils/utils";

export class PrescriptionPdfmake {
	public pdfmakeConfig = new PdfmakeConfig();
	public images = {};
	public getPrintContent(
		header,
		heId,
		prescriptionList,
		clinicDetails,
		userDetails,
		patientVisitData,
		patientInfo,
		healthEst
	) {
		let pageHeader, pageFooter, pageMargins;
		pageMargins = this.pdfmakeConfig.getPageMargins(heId);
		pageHeader = null;

		this.images["header"] = `${Utils.S3_WEB_URL}` + healthEst.logo;
		this.images["signature"] =
			`${Utils.S3_WEB_URL}` + userDetails.signatureImg;

		var defaultHeaderConfig = new DefaultHeaderConfig();
		defaultHeaderConfig.getHeader(
			header,
			this.pdfmakeConfig,
			clinicDetails,
			userDetails
		);

		defaultHeaderConfig.getPatientInfo(
			this.pdfmakeConfig,
			patientInfo,
			patientVisitData
		);

		defaultHeaderConfig.getPrescriptionData(
			this.pdfmakeConfig,
			prescriptionList
		);
		if (userDetails.signatureImg)
			defaultHeaderConfig.getSignatureData(this.pdfmakeConfig);

		if (patientVisitData?.prescription)
			defaultHeaderConfig.getPrescriptionNotes(
				this.pdfmakeConfig,
				patientVisitData.prescription
			);

		if (patientVisitData?.followupDate)
			defaultHeaderConfig.getNextVisitDate(
				this.pdfmakeConfig,
				patientVisitData.followupDate
			);

		if (
			patientVisitData?.orderedInvestigations &&
			patientVisitData.orderedInvestigations.length > 0
		)
			defaultHeaderConfig.getOrderedInvestigations(
				this.pdfmakeConfig,
				patientVisitData.orderedInvestigations
			);

		return {
			header: function (currentPage, pageCount, pageSize) {
				return pageHeader;
			},
			footer: function (currentPage, pageCount, pageSize) {
				if (!pageFooter) {
					pageFooter = [
						{
							margin: [40, 0, 40, 0],
							canvas: [
								{ type: "line", x1: 0, y1: 0, x2: pageSize.width - 80, y2: 0 },
							],
						},
						{
							columns: [
								{
									text: currentPage + " of " + pageCount,
									alignment: "left",
									margin: [40, 0, 0, 0],
								},
								{ text: clinicDetails.address, fontSize: 10 },
							],
						},
					];
				}
				return pageFooter;
			},
			pageSize: this.pdfmakeConfig.pageSize,
			pageOrientation: this.pdfmakeConfig.pageOrientation,
			info: {
				title: "Prescription",
				author: "Summit Solutions",
				subject: "Computer generated Prescription",
			},
			pageMargins: pageMargins,
			content: this.pdfmakeConfig.content,
			images: this.images,
			// defaultStyle: {
			// 	font: "default",
			// },
		};
	}
}
