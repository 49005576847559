import { environment } from "../environments/environment";
import { environmentProd } from "../environments/environment.prod";
export default class Utils {
  public static get PRIISM_APPT_API_ENDPOINT(): string {
    return (Utils.isProduction) ? environmentProd.priismApptApiEndPoint : environment.priismApptApiEndPoint;
  }

  public static get PRIISM_API_ENDPOINT(): string {
    return (Utils.isProduction) ? environmentProd.priismApiEndPoint : environment.priismApiEndPoint;
  }

  public static get isProduction(): boolean {
    return process.env.NODE_ENV === 'development' ? false : true;
  }

  static PRIISM_WEB_URL = "https://priism.in/";
  static S3_WEB_URL = "https://d3ipk6b7f792pn.cloudfront.net/";
  // static envUrl = "https://dev.priism.in/";

  static stringAvatar(name: string): {
    sx: { bgcolor: string };
    children: string;
  } {
    return {
      sx: {
        bgcolor: this.stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  static stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  static calculateAge(date: any) {
    var ageDifMs = Date.now() - date;
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
}
