import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import "./AppointmentsTable.css";
import {
	Box,
	Button,
	capitalize,
	Card,
	CardContent,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	TableSortLabel,
	Tooltip,
	Typography,
} from "@mui/material";
import Utils from "../../utils/utils";
import axios from "axios";
import moment from "moment";
import Spinner from "../../components/spinner/spinner";
import { visuallyHidden } from "@mui/utils";
import {
	ArrowRepeat,
	Calendar2Week,
	Calendar2X,
	CalendarX,
	CheckCircleFill,
	Files,
	Plus,
	Upload,
	X,
	XCircle,
} from "react-bootstrap-icons";
import { UserContext } from "../../components/layout/Layout";
import AppointmentService from "../../services/AppointmentService";
import RescheduleAnAppointment from "../reschedule-an-appointment/RescheduleAnAppointment";
import { DropzoneArea } from "material-ui-dropzone";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MedicationIcon from "@mui/icons-material/Medication";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { UploadFile } from "@mui/icons-material";
import FolderOutlinedIcon from "@mui/icons-material/Folder";
import ListItemIcon from "@mui/material/ListItemIcon";
import { SharedService } from "../../shared.service";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/Delete";

type Order = "asc" | "desc";

type AppointmentsTableState = {
	order: Order;
	orderBy: string;
	page: number;
	rowsPerPage: number;
	appointmentsBooked: any;
	isLoading: boolean;
	showCancelDialog: boolean;
	showUploadFilesDialog: boolean;
	files: any;
	apptId: string;
	showRescheduleAppointmentDialog: boolean;
	apptDetails: any;
	heId: string;
	referenceCode: any;
	value: number;
	uploadedFiles: any;
	websiteUploadedDocuments: any;
	patientVisitList: any;
	appointmentsHitory: any;
	healthEst: any;
};

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

interface EnhancedTableProps {
	onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#b56ce2",
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator<Key extends keyof any>(
	order: Order,
	orderBy: Key
): (
	a: { [key in Key]: number | string },
	b: { [key in Key]: number | string }
) => number {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}
const headCellsUpcoming = [
	{
		id: "start",
		sortColumn: true,
		numeric: false,
		label: "Appointment date",
	},
	{
		id: "startTime",
		sortColumn: true,
		numeric: false,
		label: "Time",
	},
	{
		id: "modeOfAdmission",
		sortColumn: true,
		numeric: false,
		label: "Mode",
	},
	{
		id: "locName",
		sortColumn: true,
		numeric: false,
		label: "Centre",
	},
	{
		id: "userName",
		sortColumn: true,
		numeric: false,
		label: "Consultant",
	},
	{
		id: "status",
		sortColumn: true,
		numeric: false,
		label: "Status",
	},
	{
		id: "action",
		sortColumn: false,
		numeric: false,
		label: "Action",
	},
];

const headCellsVisited = [
	{
		id: "visitDate",
		sortColumn: true,
		numeric: false,
		label: "Visited date",
	},
	{
		id: "modeOfAdmission",
		sortColumn: true,
		numeric: false,
		label: "Mode",
	},
	{
		id: "diagnosisName",
		sortColumn: true,
		numeric: false,
		label: "Diagnosis Name",
	},
	{
		id: "action",
		sortColumn: false,
		numeric: false,
		label: "Action",
	},
];

function EnhancedTableHead(props) {
	const {
		order,
		orderBy,
		rowCount,
		onRequestSort,
		headCells,
		showActionColumn,
	} = props;
	const createSortHandler =
		(property: string) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};
	const filteredHeadCells = headCells.filter(
		(cell) => showActionColumn || cell.id !== "action"
	);

	return (
		<TableHead>
			<TableRow>
				{filteredHeadCells.map((headCell) => (
					<StyledTableCell
						key={headCell.id}
						align={
							headCell.sortColumn
								? headCell.numeric
									? "right"
									: "left"
								: "inherit"
						}
						sortDirection={
							headCell.sortColumn
								? orderBy === headCell.id
									? order
									: false
								: false
						}
					>
						{headCell.sortColumn ? (
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label}
								{orderBy === headCell.id ? (
									<Box component="span" sx={visuallyHidden}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>
						) : (
							headCell.label
						)}
					</StyledTableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

const sharedService = new SharedService();
export default class AppointmentsTable extends React.Component<
	any,
	AppointmentsTableState
> {
	static contextType = UserContext;
	constructor(props: any) {
		super(props);
		this.state = {
			order: "desc",
			orderBy: "start",
			page: 0,
			rowsPerPage: 5,
			appointmentsBooked: [],
			appointmentsHitory: [],
			isLoading: true,
			showCancelDialog: false,
			showRescheduleAppointmentDialog: false,
			apptId: "",
			apptDetails: "",
			heId: "",
			referenceCode: localStorage.getItem("referenceCode"),
			showUploadFilesDialog: false,
			files: [],
			uploadedFiles: [],
			websiteUploadedDocuments: [],
			value: 0,
			patientVisitList: [],
			healthEst: [],
		};
	}
	handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
		const isAsc = this.state.orderBy === property && this.state.order === "asc";
		this.setState({ order: isAsc ? "desc" : "asc" });
		this.setState({ orderBy: property });
	};

	handleFileChange = (files: File[]) => {
		this.setState({ files });
	};

	handleUploadFiles() {
		const { apptId, files } = this.state;
		const formData = new FormData();
		files.forEach((file) => {
			formData.append("file", file);
		});
		axios
			.post(
				`${Utils.PRIISM_API_ENDPOINT}/appt_bookings/upload_files/${this.context["heid"]}/${this.state.referenceCode}/${this.context["patient_id"]}/${this.context["patient_no"]}/`,
				formData
			)
			.then((res) => {
				const newUploadedFiles = [...this.state.uploadedFiles, ...files];
				this.setState({
					showUploadFilesDialog: false,
					apptId: "",
					files: [],
					uploadedFiles: newUploadedFiles,
				});
				localStorage.setItem("uploadedFiles", JSON.stringify(newUploadedFiles));
			})
			.catch((error) => {
				// this.setState({ isLoading: false, saveError: true });
				console.error(error);
			});
	}

	handleChange = (event: React.SyntheticEvent, newValue: number) => {
		this.setState({ value: newValue });
	};

	// Avoid a layout jump when reaching the last page with empty rows.
	// emptyRows =
	//   this.state.page > 0
	//     ? Math.max(
	//         0,
	//         (1 + this.state.page) * this.state.rowsPerPage -
	//           this.state.appointmentsBooked.length
	//       )
	//     : 0;

	componentDidMount(): void {
		if (this.context["id"] && this.context["patient_no"]) {
			axios
				.get(
					`${Utils.PRIISM_API_ENDPOINT}/appt_bookings/patientapptUpcoming/${this.state.referenceCode}/${this.context["patient_no"]}/`
				)
				.then((res) => {
					const appointmentsBooked = res.data;
					this.setState({ appointmentsBooked, isLoading: false });
				})
				.catch((error) => {
					this.setState({ appointmentsBooked: [], isLoading: false });
					console.error(error);
				});
		} else {
			this.setState({ appointmentsBooked: [], isLoading: false });
		}
		this.getPatientAppointmentsHistory();
		this.getPatientVisits();
		this.getHealthEstForAppt();
		const uploadedFiles = localStorage.getItem("uploadedFiles");
		if (uploadedFiles) {
			this.setState({ uploadedFiles: JSON.parse(uploadedFiles) });
		}
	}

	getPatientAppointmentsHistory(): void {
		if(this.context["patient_no"]){
			axios
			.get(
				`${Utils.PRIISM_API_ENDPOINT}/appt_bookings/patientappthistory/${this.state.referenceCode}/${this.context["patient_no"]}/`
			)
			.then((res) => {
				const appointmentsHitory = res.data;
				this.setState({ appointmentsHitory, isLoading: false });
			})
			.catch((error) => {
				this.setState({ appointmentsHitory: [], isLoading: false });
				console.error(error);
			});
		}
	}

	getPatientVisits(): void {
		if(this.context["patient_no"]){
			axios
			.get(
				`${Utils.PRIISM_API_ENDPOINT}/patientvisits/visitlist/${this.context["heid"]}/${this.context["patient_no"]}/`
			)
			.then((res) => {
				const patientVisitList = res.data.patientDetails.patientVisits || [];
				const websiteUploadedDocuments =
					res.data.patientDetails.websiteUploadedDocuments;
				this.setState({
					patientVisitList,
					websiteUploadedDocuments,
					isLoading: false,
				});
			})
			.catch((error) => {
				this.setState({ patientVisitList: [], isLoading: false });
				console.error(error);
			});
		}
	}

	getPrescription(row) {
		let healthEst = this.state.healthEst;
		let patientInfo = this.context;
		let data = row;
		let heId = null;
		let userId = null;
		if (data.updatedBy) {
			if (typeof data.updatedBy === "object" && data.updatedBy.userId)
				userId = data.updatedBy.userId;
			else userId = data.updatedBy;
			if (typeof data.updatedBy === "object" && data.updatedBy.heId)
				heId = data.updatedBy.heId;
			else heId = data.updatedBy;
		}
		if (userId) {
			axios
				.get(
					`${Utils.PRIISM_API_ENDPOINT}/users/userClinicAppt/${heId}/${userId}/`
				)
				.then((resp) => {
					let user = resp.data.user;
					let clinic = resp.data.clinic;
					sharedService.prescriptionPrint(
						"Download",
						clinic,
						user,
						data,
						patientInfo,
						healthEst
					);
				})
				.catch((error) => {
					this.setState({ isLoading: false });
					console.error(error);
				});
		} else {
			sharedService.prescriptionPrint("Download", {}, {}, {}, {}, {});
		}
	}

	getHealthEstForAppt(): void {
		axios
			.get(
				`${Utils.PRIISM_API_ENDPOINT}/health_ests/healthEstDetails/${this.context["heid"]}/`
			)
			.then((res) => {
				const healthEst = res.data;
				this.setState({ healthEst, isLoading: false });
			})
			.catch((error) => {
				this.setState({ healthEst: [], isLoading: false });
				console.error(error);
			});
	}

	cancelAppointment() {
		AppointmentService.cancelAppointment(this.state["heId"], this.state.apptId)
			.then((res) => {
				window.location.reload();
			})
			.catch((error) => {
				console.error(error);
			});
	}

	checkIsDatePast(appt) {
		const currentDate = moment();
		const startDateTime = moment(appt.start);

		const isStartValid = startDateTime.isSameOrAfter(currentDate);
		return isStartValid;
	}

	gotoCreateAppointment = () => {
		window.location.href = "/home/" + localStorage.getItem("referenceCode") || null;
	}

	render() {
		const { showUploadFilesDialog, files, websiteUploadedDocuments } =
			this.state;
		return (
			<Card sx={{ minWidth: 700, mt: 5 }}>
				<CardContent>
					<Box display="flex" justifyContent="flex-end">
						<Button
							variant="contained"
							color="success"
							onClick={this.gotoCreateAppointment}
							startIcon={<Plus />}
						>
							Book an appointment
						</Button>
					</Box>
					<Box sx={{ width: "100%" }}>
						<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
							<Tabs
								value={this.state.value}
								onChange={this.handleChange}
								aria-label="basic tabs example"
							>
								<Tab label="Appointments" {...a11yProps(0)} />
								<Tab label="Visited" {...a11yProps(1)} />
								<Tab label="Uploads" {...a11yProps(2)} />
							</Tabs>
						</Box>
					</Box>
					{/* upcoming appointments */}
					<CustomTabPanel value={this.state.value} index={0}>
						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 700 }} aria-label="customized table">
								<TableHead>
									<TableRow>
										<StyledTableCell
											align="center"
											colSpan={7}
											style={{
												backgroundColor: "#390ba8",
											}}
										>
											Upcoming Appointments
										</StyledTableCell>
									</TableRow>
								</TableHead>
								<EnhancedTableHead
									order={this.state.order}
									orderBy={this.state.orderBy}
									onRequestSort={this.handleRequestSort}
									rowCount={this.state.appointmentsBooked.length}
									headCells={headCellsUpcoming}
									showActionColumn={true}
								/>
								<TableBody>
									{this.state.appointmentsBooked
										.slice()
										.sort(getComparator(this.state.order, this.state.orderBy))
										.slice(
											this.state.page * this.state.rowsPerPage,
											this.state.page * this.state.rowsPerPage +
												this.state.rowsPerPage
										)
										.map((row, index) => {
											const status = row.status.toLowerCase();
											return (
												<StyledTableRow key={row.apptId}>
													<StyledTableCell align="left">
														{moment(row.start).format("DD-MMM-YYYY")}
													</StyledTableCell>
													<StyledTableCell align="left">
														{row.startTime}
													</StyledTableCell>
													<StyledTableCell align="left">
														{row.modeOfAdmission === "Online"
															? "Online"
															: "Personal visit"}
													</StyledTableCell>
													<StyledTableCell align="left">
														{row.locName || "-"}
													</StyledTableCell>
													<StyledTableCell align="left">
														<span style={{ marginRight: 2, float: "left" }}>
															{capitalize(row.doctor.firstName || "")}
														</span>
														{row.doctor.lastName && (
															<span style={{ marginRight: 2 }}>
																{row.doctor.lastName}
															</span>
														)}
													</StyledTableCell>
													<StyledTableCell align="left">
														{status === "booked" && (
															<Chip
																color="primary"
																label="Booked"
																icon={<CheckCircleFill />}
															/>
														)}
														{status === "attended" && (
															<Chip
																color="success"
																label="Attended"
																icon={<CheckCircleFill />}
															/>
														)}
														{status === "cancelled" && (
															<Chip
																color="error"
																label="Cancelled"
																icon={<XCircle />}
															/>
														)}
														{status === "missed" && (
															<Chip
																color="secondary"
																label="Missed"
																icon={<CalendarX />}
															/>
														)}
														{status === "rescheduled" && (
															<Chip
																color="warning"
																label="Rescheduled"
																icon={<ArrowRepeat />}
															/>
														)}
													</StyledTableCell>
													<StyledTableCell align="left" sx={{ p: 0 }}>
														{/* {(status === "booked" || status === "missed") && (
															<Tooltip title="Reschedule apppointment">
																<IconButton
																	color="secondary"
																	href={`/reschedule-appointment/${row.heId}/${row.apptId}`}
																>
																	<Calendar2Week />
																</IconButton>
															</Tooltip>
														)} */}
														{this.checkIsDatePast(row) &&
															(status === "booked" ||
																status === "rescheduled") && (
																<Tooltip title="Cancel apppointment">
																	<IconButton
																		color="error"
																		onClick={() => {
																			this.setState({
																				showCancelDialog: true,
																				apptId: row.apptId,
																				heId: row.heId,
																			});
																		}}
																	>
																		<Calendar2X />
																	</IconButton>
																</Tooltip>
															)}
													</StyledTableCell>
												</StyledTableRow>
											);
										})}
								</TableBody>
								{this.state.appointmentsBooked &&
									!this.state.isLoading &&
									this.state.appointmentsBooked.length === 0 && (
										<TableBody>
											<TableRow>
												<TableCell rowSpan={2} />
												<TableCell colSpan={4} align="center">
													Hurray !!! You haven't booked any appointments
												</TableCell>
											</TableRow>
										</TableBody>
									)}
								{this.state.isLoading && <Spinner />}
							</Table>
							{this.state.appointmentsBooked &&
								this.state.appointmentsBooked.length > 0 && (
									<TablePagination
										rowsPerPageOptions={[5, 10, 25]}
										component="div"
										count={this.state.appointmentsBooked.length}
										rowsPerPage={this.state.rowsPerPage}
										page={this.state.page}
										onPageChange={(event, newPage) => {
											this.setState({ page: newPage });
										}}
										onRowsPerPageChange={(event) => {
											this.setState({
												page: 0,
												rowsPerPage: parseInt(event.target.value, 10),
											});
										}}
									/>
								)}
						</TableContainer>
						<br />
						<br />
						{/* History of appointmetns */}
						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 700 }} aria-label="customized table">
								<TableHead>
									<TableRow>
										<StyledTableCell
											align="center"
											colSpan={6}
											style={{
												backgroundColor: "#390ba8",
											}}
										>
											Appointments History
										</StyledTableCell>
									</TableRow>
								</TableHead>
								<EnhancedTableHead
									order={this.state.order}
									orderBy={this.state.orderBy}
									onRequestSort={this.handleRequestSort}
									rowCount={this.state.appointmentsHitory.length}
									headCells={headCellsUpcoming}
									showActionColumn={false}
								/>
								<TableBody>
									{this.state.appointmentsHitory
										.slice()
										.sort(getComparator(this.state.order, this.state.orderBy))
										.slice(
											this.state.page * this.state.rowsPerPage,
											this.state.page * this.state.rowsPerPage +
												this.state.rowsPerPage
										)
										.map((row, index) => {
											const status = row.status.toLowerCase();
											return (
												<StyledTableRow key={row.apptId}>
													<StyledTableCell align="left">
														{moment(row.start).format("DD-MMM-YYYY")}
													</StyledTableCell>
													<StyledTableCell align="left">
														{row.startTime}
													</StyledTableCell>
													<StyledTableCell align="left">
														{row.modeOfAdmission === "Online"
															? "Online"
															: "Personal visit"}
													</StyledTableCell>
													<StyledTableCell align="left">
														{row.locName || "-"}
													</StyledTableCell>
													<StyledTableCell align="left">
														<span style={{ marginRight: 2, float: "left" }}>
															{capitalize(row.doctor.firstName || "")}
														</span>
														{row.doctor.lastName && (
															<span style={{ marginRight: 2 }}>
																{row.doctor.lastName}
															</span>
														)}
													</StyledTableCell>
													<StyledTableCell align="left">
														{status === "booked" && (
															<Chip
																color="primary"
																label="Booked"
																icon={<CheckCircleFill />}
															/>
														)}
														{status === "visited" && (
															<Chip
																color="success"
																label="Attended"
																icon={<CheckCircleFill />}
															/>
														)}
														{status === "cancelled" && (
															<Chip
																color="error"
																label="Cancelled"
																icon={<XCircle />}
															/>
														)}
														{status === "missed" && (
															<Chip
																color="secondary"
																label="Missed"
																icon={<CalendarX />}
															/>
														)}
														{status === "rescheduled" && (
															<Chip
																color="warning"
																label="Rescheduled"
																icon={<ArrowRepeat />}
															/>
														)}
													</StyledTableCell>
												</StyledTableRow>
											);
										})}
								</TableBody>
								{this.state.appointmentsHitory &&
									!this.state.isLoading &&
									this.state.appointmentsHitory.length === 0 && (
										<TableBody>
											<TableRow>
												<TableCell rowSpan={2} />
												<TableCell colSpan={4} align="center">
													Sorry !!! No previous appointments found..
												</TableCell>
											</TableRow>
										</TableBody>
									)}
								{this.state.isLoading && <Spinner />}
							</Table>
							{this.state.appointmentsHitory &&
								this.state.appointmentsHitory.length > 0 && (
									<TablePagination
										rowsPerPageOptions={[5, 10, 25]}
										component="div"
										count={this.state.appointmentsHitory.length}
										rowsPerPage={this.state.rowsPerPage}
										page={this.state.page}
										onPageChange={(event, newPage) => {
											this.setState({ page: newPage });
										}}
										onRowsPerPageChange={(event) => {
											this.setState({
												page: 0,
												rowsPerPage: parseInt(event.target.value, 10),
											});
										}}
									/>
								)}
						</TableContainer>
					</CustomTabPanel>

					{/* visited */}
					<CustomTabPanel value={this.state.value} index={1}>
						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 700 }} aria-label="customized table">
								<EnhancedTableHead
									order={this.state.order}
									orderBy={this.state.orderBy}
									onRequestSort={this.handleRequestSort}
									rowCount={this.state.patientVisitList.length}
									headCells={headCellsVisited}
									showActionColumn={true}
								/>
								<TableBody>
									{this.state.patientVisitList
										.slice()
										.sort(getComparator(this.state.order, this.state.orderBy))
										.slice(
											this.state.page * this.state.rowsPerPage,
											this.state.page * this.state.rowsPerPage +
												this.state.rowsPerPage
										)
										.map((row, index) => {
											return (
												<StyledTableRow key={row.visitId}>
													<StyledTableCell align="left">
														{moment(row.visitDate).format("DD-MMM-YYYY")}
													</StyledTableCell>
													<StyledTableCell align="left">
														{row.modeOfAdmission}
													</StyledTableCell>
													<StyledTableCell align="left">
														{row.diagnosis
															.map((d) => d.diagnosisName)
															.join(", ") || "-"}
													</StyledTableCell>
													<StyledTableCell align="left" sx={{ p: 0 }}>
														{/* <Tooltip title="Download prescription"> */}
														<Button
															variant="outlined"
															color="secondary"
															startIcon={<FileDownloadOutlinedIcon />}
															onClick={() => {
																this.getPrescription(row);
															}}
														>
															Prescription
														</Button>
														{/* <IconButton
																color="secondary"
																onClick={() => {
																	this.getPrescription(row);
																}}
															>
																<MedicationIcon fontSize="large" />
															</IconButton> */}
														{/* </Tooltip> */}
													</StyledTableCell>
												</StyledTableRow>
											);
										})}
								</TableBody>
								{this.state.patientVisitList &&
									!this.state.isLoading &&
									this.state.patientVisitList.length === 0 && (
										<TableBody>
											<TableRow>
												<TableCell rowSpan={2} />
												<TableCell colSpan={5}>
													Sorry !!! You don't have any previous visits scheduled
													yet. Book an appointment to consult with our expert
													psychiatrists and take the first step towards your
													mental well-being!
												</TableCell>
											</TableRow>
										</TableBody>
									)}
								{this.state.isLoading && <Spinner />}
							</Table>
							{this.state.patientVisitList &&
								this.state.patientVisitList.length > 0 && (
									<TablePagination
										rowsPerPageOptions={[5, 10, 25]}
										component="div"
										count={this.state.patientVisitList.length}
										rowsPerPage={this.state.rowsPerPage}
										page={this.state.page}
										onPageChange={(event, newPage) => {
											this.setState({ page: newPage });
										}}
										onRowsPerPageChange={(event) => {
											this.setState({
												page: 0,
												rowsPerPage: parseInt(event.target.value, 10),
											});
										}}
									/>
								)}
						</TableContainer>
					</CustomTabPanel>
					{/* uploads */}
					<CustomTabPanel value={this.state.value} index={2}>
						{this.context["patient_no"]&&
							<Grid item>
								<Button
									variant="contained"
									color="secondary"
									startIcon={<Upload />}
									onClick={() => {
										this.setState({
											showUploadFilesDialog: true,
										});
									}}
								>
									Upload Your Documents
								</Button>
							</Grid>
						}
						<Grid item>
							<div
								style={{
									border: "1px solid #ccc",
									borderRadius: "4px",
									margin: "15px auto",
								}}
							>
								<Typography
									variant="h6"
									sx={{
										backgroundColor: "#b56ce2",
										padding: "13px",
										borderRadius: "4px 4px 0 0",
										fontSize: "1.02rem",
										color: "white",
									}}
								>
									Uploaded Files
								</Typography>
								{websiteUploadedDocuments.length > 0 ? (
									<div
										style={{
											padding: "20px",
										}}
									>
										{websiteUploadedDocuments.map((file, index) => (
											<div
												key={index}
												style={{
													display: "flex",
													alignItems: "center",
													border: "1px solid #ddd",
													marginBottom: "8px",
													backgroundColor: "#f5f5f5",
													padding: "8px",
												}}
											>
												<FolderOutlinedIcon sx={{ ml: 2, mr: 1 }} />
												<div
													style={{
														flexGrow: 1,
														padding: "8px",
														display: "flex",
														flexDirection: "column",
														justifyContent: "center",
													}}
												>
													<a
														href={`${Utils.S3_WEB_URL}/${file.path}`}
														target="_blank"
														rel="noopener noreferrer"
														style={{ textDecoration: "none", color: "black" }}
													>
														{file.filename}
													</a>
												</div>
												{/* <IconButton
													// onClick={() => this.handleDeleteFile(index)}
													edge="end"
													aria-label="delete"
												>
													<DeleteOutlinedIcon sx={{ mr: 2 }} />
												</IconButton> */}
											</div>
										))}
									</div>
								) : (
									<Typography
										variant="body1"
										sx={{
											padding: "8px",
										}}
									>
										No files uploaded yet.
									</Typography>
								)}
							</div>
						</Grid>
						{/* <Grid item>
							<Button
								variant="contained"
								color="secondary"
								startIcon={<Upload />}
								onClick={() => {
									this.setState({
										showUploadFilesDialog: true,
									});
								}}
							>
								Upload Your documents
							</Button>
						</Grid>
						<Grid item>
							<Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
								Uploaded Files:
							</Typography>
							{uploadedFiles.length > 0 ? (
								<List
									sx={{
										border: "1px solid #ccc",
										borderRadius: "4px",
										marginBottom: "8px",
										padding: "8px",
										width: "auto",
									}}
								>
									{uploadedFiles.map((file, index) => (
										<ListItem
											key={index}
											component="a"
											href={`${Utils.PRIISM_API_ENDPOINT}` + "/   " + file.path}
											target="_blank"
											rel="noopener noreferrer"
										>
											<ListItemIcon>
												<FolderIcon />
											</ListItemIcon>
											<ListItemText primary={file.path} />
										</ListItem>
									))}
								</List>
							) : (
								<Typography variant="body1">No files uploaded yet.</Typography>
							)}
						</Grid> */}
					</CustomTabPanel>
				</CardContent>

				<Dialog
					fullWidth
					maxWidth="sm"
					open={this.state.showCancelDialog}
					onClose={() => {
						this.setState({ showCancelDialog: false });
					}}
					aria-labelledby="responsive-dialog-title"
				>
					<DialogTitle
						sx={{ backgroundColor: "#1976d2", color: "white" }}
						id="responsive-dialog-title"
					>
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<Box sx={{ display: "flex", mr: 2 }}>
								<CalendarX size={25} />
							</Box>
							Cancel appointment
						</Box>
					</DialogTitle>
					<DialogContent>
						<DialogContentText component="div" sx={{ pt: 2 }}>
							Are you sure want to cancel the appointment?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							autoFocus
							variant="contained"
							color="error"
							endIcon={<XCircle />}
							onClick={() => {
								this.setState({ showCancelDialog: false, apptId: "" });
							}}
						>
							Discard
						</Button>
						<Button
							variant="contained"
							autoFocus
							endIcon={<X />}
							onClick={() => {
								this.cancelAppointment();
							}}
						>
							Yes, Cancel it!
						</Button>
					</DialogActions>
				</Dialog>
				{this.state.showRescheduleAppointmentDialog && (
					<RescheduleAnAppointment
						{...this.state}
						onDiscardReschedule={() => {
							this.setState({ showRescheduleAppointmentDialog: false });
						}}
						onRescheduleSuccess={() => {
							this.setState({ showRescheduleAppointmentDialog: false });
							window.location.reload();
						}}
					/>
				)}
				{/* uploading files */}
				<Dialog
					fullWidth
					maxWidth="sm"
					open={showUploadFilesDialog}
					onClose={() => {
						this.setState({ showUploadFilesDialog: false });
					}}
					aria-labelledby="responsive-dialog-title"
				>
					<DialogTitle
						sx={{ backgroundColor: "#1976d2", color: "white" }}
						id="responsive-dialog-title"
					>
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<Box sx={{ display: "flex", mr: 2 }}>
								<Upload size={25} />
							</Box>
							Upload Files
						</Box>
					</DialogTitle>
					<DialogContent>
						<DialogContentText
							component="div"
							sx={{ pt: 2 }}
						></DialogContentText>
						<DropzoneArea
							onChange={this.handleFileChange}
							acceptedFiles={["image/*", "application/pdf"]}
							showPreviews={true}
							showPreviewsInDropzone={false}
							useChipsForPreview
							previewText="Selected files"
							showAlerts={false}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							autoFocus
							variant="contained"
							color="error"
							endIcon={<XCircle />}
							onClick={() => {
								this.setState({ showUploadFilesDialog: false, apptId: "" });
							}}
						>
							Discard
						</Button>
						<Button
							variant="contained"
							autoFocus
							endIcon={<Upload />}
							onClick={() => {
								this.handleUploadFiles();
							}}
							disabled={files.length === 0}
						>
							upload
						</Button>
					</DialogActions>
				</Dialog>
			</Card>
		);
	}
}
